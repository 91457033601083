import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import Twocol from "../components/strapi/structure/TwoCol";
import Craftsmanship from "../components/strapi/structure/Craftsmanship";
import Outropiggy from "../components/strapi/structure/Outropiggy";

export default function HotTubs({ data }) {
  return (
    <Layout>
      <SEO title="Hot Tubs" />
      {data.allStrapiSeries500.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.s500_mh.mh_title}
            mhsubtitle={node.s500_mh.mh_subtitle}
            mhbackground={node.s500_mh.mh_bg.publicURL}
          />
          <Twocol
            title={node.s500_m510.txtimg_title}
            description={node.s500_m510.txtimg_description}
            blueTitle={node.s500_m510.txtimg_bluetitle}
            blueLink={node.s500_m510.txtimg_bluelink}
            order={node.s500_m510.txtimg_order}
            rowimg={node.s500_m510.txtimg_img.publicURL}
          />
          <Twocol
            title={node.s500_m530.txtimg_title}
            description={node.s500_m530.txtimg_description}
            blueTitle={node.s500_m530.txtimg_bluetitle}
            blueLink={node.s500_m530.txtimg_bluelink}
            bgColor={node.s500_m530.txtimg_bgcolor}
            order={node.s500_m530.txtimg_order}
            rowimg={node.s500_m530.txtimg_img.publicURL}
          />
          <Twocol
            title={node.s500_m540.txtimg_title}
            description={node.s500_m540.txtimg_description}
            blueTitle={node.s500_m540.txtimg_bluetitle}
            blueLink={node.s500_m540.txtimg_bluelink}
            bgColor={node.s500_m540.txtimg_bgcolor}
            order={node.s500_m540.txtimg_order}
            rowimg={node.s500_m540.txtimg_img.publicURL}
          />
          <Twocol
            title={node.s500_m550.txtimg_title}
            description={node.s500_m550.txtimg_description}
            blueTitle={node.s500_m550.txtimg_bluetitle}
            blueLink={node.s500_m550.txtimg_bluelink}
            bgColor={node.s500_m550.txtimg_bgcolor}
            order={node.s500_m550.txtimg_order}
            rowimg={node.s500_m550.txtimg_img.publicURL}
          />
          <Twocol
            title={node.s500_m570.txtimg_title}
            description={node.s500_m570.txtimg_description}
            blueTitle={node.s500_m570.txtimg_bluetitle}
            blueLink={node.s500_m570.txtimg_bluelink}
            bgColor={node.s500_m570.txtimg_bgcolor}
            order={node.s500_m570.txtimg_order}
            rowimg={node.s500_m570.txtimg_img.publicURL}
          />
          <Twocol
            title={node.s500_m590.txtimg_title}
            description={node.s500_m590.txtimg_description}
            blueTitle={node.s500_m590.txtimg_bluetitle}
            blueLink={node.s500_m590.txtimg_bluelink}
            bgColor={node.s500_m590.txtimg_bgcolor}
            order={node.s500_m590.txtimg_order}
            rowimg={node.s500_m590.txtimg_img.publicURL}
          />
          <Craftsmanship
            title={node.s500_craftsmanship.craft_title}
            description={node.s500_craftsmanship.craft_description}
          />
          <Outropiggy outro={node.s500_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query Series500 {
    allStrapiSeries500 {
      edges {
        node {
          id # id of the node
          s500_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          s500_m510 {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          s500_m530 {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          s500_m540 {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          s500_m550 {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          s500_m570 {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          s500_m590 {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          s500_craftsmanship {
            id
            craft_title
            craft_description
          }
          s500_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`;
